export default {
  SEARCH_BOX_INPUT_CLASS: 'form-input',
  SEARCH_BOX_PLACEHOLDER: 'Keyword, brand, SKU',
  SEARCH_BOX_BUTTON_CLASS: 'search-button',
  SEARCH_BOX_FORM_CLASS: 'form-fieldset',

  SEARCH_PAGE_CLASS: 'category',
  MAIN_CONTENT_CLASS: 'page-content__normalize',
  PRODUCT_REPEATER_CLASS: 'productCards productCards--grid',

  BUTTON_PRIMARY_CLASS: 'button button--primary YmmProductReset',
  BUTTON_SECONDARY_CLASS: 'button button--primary YmmProductReset',

  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',
  GO_TEXT: 'Find Parts',
};
