const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = ['Submodel', 'Engine'];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const ignoreFields = [...wheelsFields, ...tiresFields];

const categorySelectionPageUrl = '/categories/';
const brandSelectionPageUrl = '/brands/';
const isCategorySelectionPage = window.location.pathname === categorySelectionPageUrl;

globalThis.Convermax.handleProductCompareClick = (e) => {
  const compareBtn = window.document.body.querySelector('[data-compare-nav]');
  if (!compareBtn) {
    return;
  }

  const productsIdsToCompare = new Set(compareBtn.href.split('/'));

  if (!e.target.checked) {
    productsIdsToCompare.delete(e.target.value);
  } else {
    productsIdsToCompare.add(e.target.value);
  }

  compareBtn.querySelector('span.countPill').innerHTML =
    productsIdsToCompare.size > 4 ? productsIdsToCompare.size - 4 : '';
  compareBtn.classList.toggle('show', productsIdsToCompare.size > 4);
  compareBtn.href = `${Array.from(productsIdsToCompare).join('/')}`;
};

export default {
  platform: 'bigcommerce',
  defaultView: 'grid',
  SearchRequestDefaults: {
    pageSize: 36,
  },
  page: {
    getPageType: (defaults) =>
      window.document.querySelector('.cm_brand_page')
        ? 'brand'
        : window.document.querySelector('.cm_category_page') &&
            window.location.pathname !== categorySelectionPageUrl
          ? 'category'
          : window.location.pathname === categorySelectionPageUrl
            ? 'parts'
            : defaults.getter(),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    categorySelectionPages: [
      { pathname: categorySelectionPageUrl, field: 'category' },
      { pathname: brandSelectionPageUrl, field: 'brand_name' },
    ],
  },
  facets: {
    rangedFacet: [{ fields: ['wheel_bore'], step: '1/10//1', slider: { mode: 'LowerBound' } }],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '.category--grid',
      template: 'SearchPage',
      visibleIf: () => !isCategorySelectionPage,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'name': 'A to Z',
        'name:desc': 'Z to A',
        'price': 'Price: Ascending',
        'price:desc': 'Price: Descending',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: '#quickSearch .form', class: 'cm_search-box-root__wrapper form' },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        replace: 'header .menu',
        class: 'cm_vehicle-widget__header-container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 800,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'FitmentTable',
      location: {
        replace: '#tab-fitment',
        class: 'tab-content',
        id: 'tab-fitment',
      },
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FacetCategoryTiles',
      type: 'FacetTiles',
      location: '.category--grid',
      template: 'facetTiles/hierarchicalContainer',
      isVehicleWidgetDisabled: true,
      visibleIf: () => isCategorySelectionPage,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: 'main.cm_brand_page .brandGrid',
      template: 'facetTiles/hierarchicalContainer',
      view: 'columns',
      isVehicleWidgetDisabled: true,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'VehicleWidgetDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
