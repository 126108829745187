//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-760:_2350,_2560,_1976,_7096,_3832,_856,_8248,_9371;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-760')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-760', "_2350,_2560,_1976,_7096,_3832,_856,_8248,_9371");
        }
      }catch (ex) {
        console.error(ex);
      }