import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
SearchResult,
FacetPanel,
FacetBar,
SearchBoxDialogButton,
VehicleWidget,
FitmentTable,
FacetTiles,
FacetDialog,
SearchBoxDialog,
ContextDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};